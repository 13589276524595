import React from 'react';
import styles from './Home.module.css';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      <div className={styles.header}>Our Services</div>
      <div className={styles.hero}>
        <Link to="/services" className={styles.logoContainer}>
          <img src={logo} alt="Zialectics LLC Logo" className={styles.logo} />
          <span className={styles.hoverText}>Services</span>
        </Link>
        <Link to="/about" className={styles.aboutLink}>
          <h1 className={styles.title}>Zialectics</h1>
          <span className={styles.hoverText}>About Us</span>
        </Link>
        <p className={styles.description}>
          Your trusted partner in business-first technology. At Zialectics, we focus on outcomes, integrity, and purpose-driven technology to solve real business problems.
        </p>
        <Link to="/about" className={styles.learnMoreLink}>
          Learn more about our tenets.
        </Link>
        <Link to="/contact">
          <button className={styles.ctaButton}>
            Contact Us
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
