import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Services.module.css';

const services = [
  {
    id: 'digital-asset-creation',
    title: 'Digital Asset Creation',
    description: 'From web applications to custom software, we develop tools that generate value and drive business efficiency.',
  },
  {
    id: 'business-consulting',
    title: 'Business Consulting',
    description: 'Strategic guidance tailored to align technology with your core business objectives.',
  },
  {
    id: 'project-management',
    title: 'Project Management',
    description: 'Comprehensive oversight of technical projects, ensuring on-time delivery and measurable success.',
  },
];

const Services = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Our Services</h1>
        <div className={styles.servicesGrid}>
          {services.map((service) => (
            <Link key={service.id} to={`/services/${service.id}`} className={styles.serviceItem}>
              <h2 className={styles.serviceTitle}>{service.title}</h2>
              <p>{service.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
