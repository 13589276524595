import React from 'react';

const Tenets = () => {
  const tenets = [
    {
        title: 'Technology is a Tool with Purpose; Not Our Purpose',
        description:
          'Technology is only built when it’s the best solution to a well-framed problem. If recommending simple process improvements or referring clients to external resources is the best solution, we will recommend that first. While we love to build “cool tech”, it has no place unless it serves a clear business purpose.',
      },
    {
      title: 'Partners are More Than Vendors',
      description: 'Zialectics is a partner first, measuring success by the lasting impact and trust built with clients, not just the initial deliverables.',
    },
    {
      title: 'We Focus on Outcomes. Not Outputs',
      description: 'Zialectics measures success through the value created for the customer, be it cost savings, time efficiency, or business growth.',
    },

  ];

  return (
    <div className="bg-gray-50 pt-1 px-8 pb-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-6 text-center">Our Tenets</h2>
      <ul className="list-disc list-inside text-lg">
        {tenets.map((tenet, index) => (
          <li key={index} className="mb-4">
            <strong>{tenet.title}:</strong> {tenet.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tenets;