import React from 'react';
import styles from './About.module.css';
import Tenets from './Tenets';

const About = () => {
  return (
    <div className="bg-white text-gray-900 min-h-screen p-8">
      <div className="max-w-4xl mx-auto prose">
        <h1 className="text-4xl font-bold text-primary mb-4 text-center">Our Vision</h1>
        <p>
          To bridge the gap between business and technology, enabling our clients to achieve sustainable growth through tailored digital solutions.

          Zialectics LLC specializes in creating technology solutions that align with real business needs, fostering trust, scalability, and efficiency. Our team thrives on delivering outcomes that truly make a difference.
        </p>

        {/* Tenets Section */}
        <Tenets />

        {/* What Sets Us Apart Section */}
        <h2 className="text-2xl font-semibold mt-6 text-center">What Sets Us Apart</h2>
        <ul>
          <li>Experience across diverse industries, including aviation, marketing, advertising technology, and data science.</li>
          <li>Commitment to long-term partnerships built on transparency and mutual success.</li>
          <li>Focus on solving the right problems with scalable, cost-effective solutions.</li>
        </ul>

      </div>
    </div>
  );
};

export default About;
