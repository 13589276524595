import React from 'react';
import { useParams } from 'react-router-dom';

const services = {
  'digital-asset-creation': {
    title: 'Digital Asset Creation',
    details: 'Our specialty is creating web applications and custom software that optimize workflows, improve efficiency, and generate value for clients.',
  },
  'business-consulting': {
    title: 'Business Consulting',
    details: 'We provide tailored strategies to align technology with your core business objectives for sustainable growth.',
  },
  'project-management': {
    title: 'Project Management',
    details: 'Our team ensures the successful planning, execution, and delivery of your technical projects on time and within budget. This starts with a clear understanding of your business requirements and goals. We then work with you to define the scope, timeline, and resources needed to achieve your objectives.',
  },
};

const ServiceDetail = () => {
  const { id } = useParams();
  const service = services[id];

  if (!service) {
    return <p>Service not found.</p>;
  }

  return (
    <div className="bg-white text-gray-900 min-h-screen p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-primary mb-6">{service.title}</h1>
        <p className="text-lg">{service.details}</p>
      </div>
    </div>
  );
};

export default ServiceDetail;
